import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { wrapper } from "./events.module.scss"

const Events = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulPage {
        edges {
          node {
            id
            pageName
            pageContent {
              raw
            }
          }
        }
      }
    }
  `)

  const { edges } = data.allContentfulPage
  const openingHoursPage = edges.find((edge) => edge.node.pageName === "events")
  const { pageContent } = openingHoursPage.node

  const Bold = ({ children }) => <b>{children}</b>
  const Italic = ({ children }) => <i>{children}</i>
  const Text = ({ children }) => <p>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }
  return (
    <Layout>
      <Seo title={"Events"} />
      <div className={wrapper}>{renderRichText(pageContent, options)}</div>
    </Layout>
  )
}

export default Events
